import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Flex, Box, Text } from 'theme-ui'

import { useMediaQuery } from 'react-responsive'
import BgImage from './BgImage'

export const ContactBanner = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const data = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: { eq: "contact.png" }) {
          childImageSharp {
            fluid(maxHeight: 430, maxWidth: 1700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Box mb="20px">
      <BgImage
        fluid={data.banner.childImageSharp.fluid}
        key={data.banner.childImageSharp.fluid.src}
        filter="sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg)"
        alt="Contact us Banner"
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: ['30px', '75px', '100px', '100px', '250px'],
          }}
        >
          <Text
            as="h1"
            sx={{
              color: 'white',
              fontFamily: 'Futura Heavy',
              fontSize: [6],
            }}
          >
            Contact Us
          </Text>
          {!isMobile && (
            <Text
              as="h4"
              sx={{
                color: 'white',
                fontFamily: 'Futura Book Regular',
                fontSize: [1, 3, 3, 3, 4],
              }}
            >
              If you want to join our community or have any questions, please
              contact us
            </Text>
          )}
        </Flex>
      </BgImage>
    </Box>
  )
}
