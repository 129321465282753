import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { ContactTabs, ContactBanner } from '@components/Contact'

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactBanner />
    <ContactTabs />
  </Layout>
)

export default Contact
