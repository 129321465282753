import React from 'react'
import { Text, Flex, Button } from 'theme-ui'

export const SubmitButton = ({
  text,
  disabled,
}: {
  text: string
  disabled?: boolean
}) => {
  return (
    <Flex
      sx={{
        minWidth: '190px',
        alignItems: 'baseline',
      }}
    >
      <Button
        disabled={disabled}
        type="submit"
        sx={{
          alignSelf: 'center',
          bg: 'primary.one',
          color: 'white',
          fontFamily: 'Futura Bold',
          my: 2,
          padding: '12px',
          borderRadius: '4px',
          border: 'none',
          cursor: 'pointer',

          '&:disabled': {
            cursor: 'not-allowed',
          },

          '&:hover': {
            opacity: 0.8,
          },
        }}
      >
        <Text sx={{ fontSize: 3, fontFamily: 'Futura Bold' }}>{text}</Text>
      </Button>
    </Flex>
  )
}
