import React, { useState, useCallback, useMemo } from 'react'
import { Flex, Input, Label, Textarea, Text, Box } from 'theme-ui'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { SubmitButton } from '../SubmitButton'
import { LocationInput } from './LocationInput'
import { DisciplineInput } from './DisciplineInput'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string().email(),
  affiliation: yup.string().required(),
})

interface Values {
  firstName: string
  lastName: string
  email: string
  country: string
  region: string
  affiliation: string
  discipline: string
  fieldOfInterest: string
  whyJoin: string
}

const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

export const JoinNetworkForm = () => {
  const validationSchema = useMemo(
    () =>
      yup.object({
        FNAME: yup.string().required('Required'),
        LNAME: yup.string().required('Required'),
        EMAIL: yup
          .string()
          .email()
          .required(),
        AFFILIATE: yup.string().required(),
        COUNTRY: yup.string().required(),
      }),
    []
  )

  const resolver = useYupValidationResolver(validationSchema)

  const methods = useForm({ resolver })
  const errors = methods.errors

  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [mailChimpResult, setMailChimpResult] = useState({})

  const onSubmit = async values => {
    const result = await addToMailchimp(values.EMAIL, values)
    Sentry.captureMessage(`Mail Chimp Response: ${JSON.stringify(result)}`)

    setMailChimpResult(result)

    if (result?.result === 'success') {
      setSubmissionSuccess(true)
    } else if (result?.result === 'error') {
      Sentry.captureMessage(
        `Error in submitting join network form: ${result.msg}`
      )
    }
  }

  const renderSubmissionText = result => {
    if (
      result?.result === 'error' &&
      result?.msg.includes('already subscribed')
    ) {
      return (
        <Text
          sx={{
            textAlign: 'left',
            fontFamily: 'Futura Medium',
            color: 'black',
            pr: '60px',
            width: '600px',
            py: [3],
            ml: [0, '410px'],
          }}
        >
          You are already subscribed to the network.
        </Text>
      )
    }
    return <></>
  }

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{
          flexDirection: 'column',
          alignItems: ['none', 'center'],
          ml: [10, 0],
          mb: '100px',
        }}
      >
        <FormInput name="FNAME" label="First name" />
        <FormInput name="LNAME" label="Last name" />
        <FormInput name="EMAIL" label="Email" />
        <LocationInput />
        <FormInput name="AFFILIATE" label="Affiliation" />
        <DisciplineInput />
        <WhyJoinInput />
        <Text
          sx={{
            textAlign: 'left',
            fontFamily: 'Futura Medium',
            color: 'black',
            pr: '60px',
            width: '600px',
            py: [3],
            ml: [0, '410px'],
          }}
        >
          We use Mailchimp as our marketing platform. By clicking below to
          subscribe, you acknowledge that your information will be transferred
          to Mailchimp for processing.{' '}
          <a href="https://mailchimp.com/legal/" target="_blank">
            Learn more about Mailchimp's privacy practices here.
          </a>
        </Text>
        <SubmitButton text="Submit Application" />
        <Flex>
          {submissionSuccess && (
            <Text
              sx={{
                textAlign: 'left',
                fontFamily: 'Futura Medium',
                color: 'black',
                pr: '60px',
                width: '600px',
                py: [3],
                ml: [0, '410px'],
              }}
            >
              Thank you for joining the Migration in Harmony Research
              Coordination Network
            </Text>
          )}
          {renderSubmissionText(mailChimpResult)}
        </Flex>
      </Flex>
    </FormProvider>
  )
}

const FormInput = ({ name, label }: { name: string; label: string }) => {
  const { register, errors } = useFormContext()

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row', 'row', 'row'],
        alignItems: 'baseline',
      }}
    >
      <Label
        sx={{
          textAlign: 'right',
          fontFamily: 'Futura Medium',
          color: 'black',
          width: '145px',
        }}
      >
        {label} *
      </Label>
      <Input
        name={name}
        ref={register}
        sx={{
          borderColor: name in errors ? 'red' : 'transparent',
          fontFamily: 'SF Pro Regular',
          fontSize: [2],
          bg: 'input',
          width: '335px',
          p: '12px',
          my: '5px',
        }}
      />
    </Flex>
  )
}

const WhyJoinInput = () => {
  const { register } = useFormContext()
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        alignItems: 'baseline',
      }}
    >
      <Label
        sx={{
          textAlign: 'right',
          fontFamily: 'Futura Medium',
          color: 'black',
          pr: '60px',
          maxWidth: '240px',
        }}
      >
        Why do you want to join MIHRCN?
      </Label>
      <Textarea
        name="WHYJOIN"
        ref={register}
        sx={{
          border: 'none',
          bg: 'input',
          minWidth: ['90vw', '430px', null, null, null],
          minHeight: '200px',
          p: '12px',
          my: '5px',
          fontFamily: 'SF Pro Regular',
          fontSize: [2],
        }}
      />
    </Flex>
  )
}

export default JoinNetworkForm
