import React from 'react'
import { Select, Flex, Label } from 'theme-ui'
import { useFormContext } from 'react-hook-form'

export const DisciplineInput = () => {
  const { register } = useFormContext()

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row', 'row', 'row'],
        alignItems: 'baseline',
      }}
    >
      <Label
        sx={{
          textAlign: 'right',
          fontFamily: 'Futura Medium',
          color: 'black',
          pr: '60px',
          width: '145px',
        }}
      >
        Discipline
      </Label>
      <Select
        name="DISCIPLINE"
        defaultValue="none"
        ref={register({ required: true })}
        sx={{
          border: 'none',
          fontFamily: 'SF Pro Regular',
          fontSize: [2],
          bg: 'input',
          width: '335px',
          p: '12px',
          my: '5px',
        }}
      >
        <option value="none" disabled hidden>
          Select an Option
        </option>
        <option value="Archeology">Archeology</option>
        <option value="Anthropology"> Anthropology</option>
        <option value="Climate change"> Climate change</option>
        <option value="Cultural heritage"> Cultural heritage</option>
        <option value="Globalization"> Globalization</option>
        <option value="Modeling"> Modeling</option>
        <option value="Demographics"> Demographics</option>
        <option value="Ecosystems"> Ecosystems</option>
        <option value="Economics"> Economics</option>
        <option value="Education"> Education</option>
        <option value="Engineering"> Engineering</option>
        <option value="Security"> Security</option>
        <option value="Social systems"> Social systems</option>
        <option value="Subsistence resources"> Subsistence resources</option>
        <option value="Traditional knowledge"> Traditional knowledge</option>
        <option value="Urbanization"> Urbanization</option>
        <option value="Other"> Other</option>
      </Select>
    </Flex>
  )
}
