/**@jsx jsx */
import { Flex, Input, Box, Textarea, jsx, Text } from 'theme-ui'
import { useForm, FormContext, useFormContext } from 'react-hook-form'
import { SubmitButton } from '../SubmitButton'

const MainForm = () => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'center',
        pb: [3],
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column'],
        }}
      >
        <VictoriaInfo />
        <UniversityInfo />
        <ArcticInstituteInfo />
      </Flex>
    </Flex>
  )
}

const ContactUsForm = () => {
  const methods = useForm()

  const onSubmit = values => {}

  return (
    <Flex
      sx={{
        flexDirection: ['column'],
        mr: ['200px'],
      }}
    >
      <FormContext {...methods}>
        <Flex
          as="form"
          netlify
          data-netlify="true"
          method="POST"
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{
            flexDirection: 'column',
            alignItems: ['none', 'center'],
            ml: [10, 0],
            mb: '100px',
          }}
        >
          <Text
            sx={{
              fontFamily: 'Futura Medium',
              maxWidth: '50vw',
              border: 'none',
              width: '335px',
              py: [2],
            }}
          >
            If you want to learn more about Migration in Harmony or join our
            team, please contact our principal investigator, Victoria Herrmann.
          </Text>
          <Text
            sx={{
              fontFamily: 'Futura Medium',
              maxWidth: '50vw',
              border: 'none',
              width: '335px',
              py: [2],
            }}
          >
            Leave a message now?
          </Text>
          <FormInput name="firstName" label="First name" />
          <FormInput name="lastName" label="Last name" />
          <FormInput name="email" label="Email" />
          <MessageBox />
          <Box
            sx={{
              mr: [null, '150px'],
            }}
          >
            <SubmitButton text="Submit" />
          </Box>
        </Flex>
      </FormContext>
    </Flex>
  )
}

const FormInput = ({ name, label }: { name: string; label: string }) => {
  const { register } = useFormContext()

  return (
    <Input
      name={name}
      placeholder={`${label} *`}
      ref={register}
      sx={{
        border: 'none',
        fontFamily: 'SF Pro Regular',
        fontSize: [2],
        bg: 'input',
        width: '335px',
        p: '12px',
        my: '5px',
      }}
    />
  )
}

const MessageBox = () => {
  const { register } = useFormContext()
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        alignItems: 'baseline',
      }}
    >
      <Textarea
        name="message"
        placeholder={'Leave a message'}
        ref={register}
        sx={{
          border: 'none',
          bg: 'input',
          minWidth: ['70vw', '375px', null, null, null],
          minHeight: '200px',
          p: '12px',
          my: '5px',
          ml: [0, '40px'],
          fontFamily: 'SF Pro Regular',
          fontSize: [2],
        }}
      />
    </Flex>
  )
}

const VictoriaInfo = () => (
  <Box
    sx={{
      mb: [3],
    }}
  >
    <Text
      sx={{
        fontFamily: 'Futura Heavy',
        fontSize: 3,
      }}
    >
      Victoria Herrmann
    </Text>
    <Text
      sx={{
        fontFamily: 'Futura Book Regular',
        color: 'grey',
      }}
    >
      Phone: +1 (202) 350-1384 <Box />
      Email:{' '}
      <a href="mailto:victoria.herrmann@thearcticinstitute.org">
        victoria.herrmann@thearcticinstitute.org
      </a>
      <Box />
      vh159@georgetown.edu
    </Text>
  </Box>
)

const UniversityInfo = () => (
  <Box
    sx={{
      mb: [3],
    }}
  >
    <Text
      sx={{
        fontFamily: 'Futura Heavy',
        fontSize: 3,
      }}
    >
      Institute for the Study of International Migration
      <Box />
      Walsh School of Foreign Service
    </Text>
    <Text
      sx={{
        fontFamily: 'Futura Book Regular',
        color: 'grey',
      }}
    >
      Georgetown University <Box />
      Harris Building 3300 Whitehaven St, NW
      <Box />
      Washington DC 20007
    </Text>
  </Box>
)

const ArcticInstituteInfo = () => (
  <Box
    sx={{
      mb: [3],
    }}
  >
    <Text
      sx={{
        fontFamily: 'Futura Heavy',
        fontSize: 3,
      }}
    >
      The Arctic Institute
    </Text>
    <Text
      sx={{
        fontFamily: 'Futura Book Regular',
        color: 'grey',
      }}
    >
      P.O. Box 21194 <Box />
      Washington, DC 20009
    </Text>
  </Box>
)

export default MainForm
