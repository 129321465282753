import React from 'react'

import { JoinNetworkForm } from './JoinNetworkForm'
import ContactUsForm from './ContactUsForm'
import Tabs from '@components/Tabs'
import { Panel } from '@bumaga/tabs'
import { Flex } from 'theme-ui'

const ContactTabHeaders = ['Join MHRCN', 'Contact information']

export const ContactTabs = () => {
  const panels = ContactTabHeaders.map(tab => {
    return (
      <Panel key={tab}>
        {tab === 'Join MHRCN' ? <JoinNetworkForm /> : <ContactUsForm />}
      </Panel>
    )
  })

  return (
    <Flex
      sx={{
        flexDirection: ['column'],
      }}
    >
      <Tabs tabHeaderConfig={ContactTabHeaders} panels={panels} />{' '}
    </Flex>
  )
}

export default ContactTabs
