/**@jsx jsx */
import React, { useState } from 'react'
import { Box, Flex, Label, jsx } from 'theme-ui'
import { CountryDropdown } from '@dojr/react-country-region-selector'
import { useFormContext } from 'react-hook-form'

export const LocationInput = () => {
  const [country, setCountry] = useState('')

  return (
    <Flex
      sx={{
        flexDirection: ['column'],
        alignItems: 'baseline',
      }}
    >
      <CountryInput country={country} setCountry={setCountry} />
    </Flex>
  )
}

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size + ''}
    height={size + ''}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
)

const DownArrow = props => (
  <SVG {...props}>
    <path d="M7 10l5 5 5-5z" />
  </SVG>
)

const CountryInput = ({
  country,
  setCountry,
}: {
  country: string
  setCountry: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { register, errors } = useFormContext()
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        alignItems: 'baseline',
      }}
    >
      <Label
        sx={{
          textAlign: 'right',
          fontFamily: 'Futura Medium',
          color: 'black',
          pr: '60px',
          width: '145px',
        }}
      >
        Country *
      </Label>
      <Flex>
        <CountryDropdown
          name="COUNTRY"
          ref={register}
          value={country}
          onChange={val => setCountry(val)}
          sx={{
            borderColor: 'COUNTRY' in errors ? 'red' : 'transparent',
            fontFamily: 'SF Pro Regular',
            fontSize: [2],
            bg: 'input',
            width: '335px',
            p: '12px',
            my: '5px',
            display: 'block',
            appearance: 'none',
            lineHeight: 'inherit',
            borderRadius: 4,
            color: 'inherit',
          }}
        />
        <DownArrow
          sx={{
            ml: -28,
            alignSelf: 'center',
            pointerEvents: 'none',
          }}
        />
      </Flex>
    </Flex>
  )
}
